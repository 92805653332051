import { graphql } from 'gatsby';
import React, { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import PackageCta from '../components/home/package-cta';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FormPopup from '../components/shared/form-popup';
import Hero from '../components/shared/hero';
import '../styles/common.css';

export default ({ data }) => {
	const [showForm, setShowForm] = useState(false);

	return (
		<Layout>
			<SEO
				title={'Информация относно COVID-19'}
				description={
					'Бъди информиран и разбери, що е то корона вирус, къде може да намериш повече информация и полезни съвети. NetSurf те призовава да си останеш вкъщи и да се пазиш.'
				}
			/>

			<Hero
				heroImage={data.heroImage.childImageSharp.fluid.src}
				undertext={
					'Какво представлява коронавирусът, какви са неговите симптоми, как се разпространява и как да се предпазим от него'
				}
				imageClass="hero-covid"
				title={
					<>
						<h1 className="text-white mt-3">COVID-19 у нас и по света</h1>
					</>
				}
			/>

			<div>
				<section id="about-quote" className="spacing-bottoms sextra-spacing">
					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<div className="container my-3">
							<div className="row mt-5 d-flex">
								<div className="col-12 col-lg-6 order-1 order-lg-0 d-flex justify-content-center align-items-center px-lg-4">
									<div>
										<h3>Обща информация за вируса</h3>
										<p>
											Новият коронавирус 2019-nCov, временно наричан
											2019-nCoV, е идентифициран в Китай в края на
											2019 г. и е нов щам на коронавирус. Въпреки че
											източник на инфекцията са животни, вирусът се
											предава от човек на човек. Това става предимно
											чрез въздушни капчици, когато хората кихат,
											кашлят или издишат. Вирусът може да причини
											умерени грипоподобни симптоми като: треска,
											кашлица, затруднено дишане, мускулни болки и
											отпадналост.
											<br />
											<br />
											Повече информация за COVID-19 може да намерите{' '}
											<strong>
												<a
													href="https://www.mh.government.bg/bg/covid-19/"
													target="_blank"
												>
													тук.
												</a>
											</strong>
										</p>
									</div>
								</div>
								<div className="col-12 col-lg-6 text-center d-flex justify-content-center align-items-center">
									<img
										src={data.block1.childImageSharp.fluid.src}
										className="img-fluid mb-2"
										alt="Снимка от създаването на NetSurf"
									/>
								</div>
							</div>
						</div>
					</ScrollAnimation>

					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<div className="container my-3">
							<div className="row mt-5 d-flex">
								<div className="col-12 col-lg-6 order-1 d-flex justify-content-center align-items-center px-lg-4">
									<div>
										<h3>Предпазни мерки за служителите ни</h3>
										<p>
											Грижим се за нашите служители, които ви
											обслужват всеки ден. С цел най-висока степен
											на безопасност, предприемаме следните мерки:
										</p>
										<ul className="blue-ticks">
											<li>
												подсигуряваме магазинна мрежа и технически
												екипи с предпазни средства (маски,
												ръкавици, очила) и дезинфектанти
											</li>
											<li>
												обработваме с препарати фирмените
												автомобили и работните помещения на всеки
												два часа
											</li>
											<li>
												инструктираме екипите да работят според
												предписанията на здравните институции за
												предотвратяване риска от потенциална
												зараза
											</li>
										</ul>
										<p>
											Всички служители извън оперативната част, с
											възможност да работят от разстояние, приеха
											инициативата #ОставамВкъщи. Отделите като кол
											център, маркетинг, финансово-счетоводен и
											административен вече работят от домовете си.
										</p>
									</div>
								</div>
								<div className="col-12 col-lg-6 text-center d-flex justify-content-center align-items-center">
									<img
										src={data.block2.childImageSharp.fluid.src}
										className="img-fluid mb-2"
										alt="Снимка от създаването на NetSurf"
									/>
								</div>
							</div>
						</div>
					</ScrollAnimation>

					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<div className="container my-3">
							<div className="row mt-5 d-flex">
								<div className="col-12 col-lg-6 order-1 order-lg-0 d-flex justify-content-center align-items-center px-lg-4">
									<div>
										<h3>Предпазни мерки за клиентите ни</h3>
										<p>
											Тук сме, за да помогнем на нашите клиенти.
											Призоваваме хората да останат по домовете си и
											да не предприемат рискове, излизайки навън.
											Насочваме клиентите ни към онлайн каналите за
											обслужване, кол център или месинджър. Така те
											лесно могат да получат информация, отдалечен
											технически съпорт или да платят своите сметки.
											Обезопасихме и магазините си, като:
										</p>
										<ul className="blue-ticks">
											<li>
												инсталирахме специални входни врати и по
												този начин спазихме мерките за безопасност
											</li>
											<li>
												поставихме специални указателни табели,
												които призовават клиентите да стоят на
												разстояние един от друг, като така
												ограничаваме и едновременния достъп
											</li>
										</ul>
									</div>
								</div>
								<div className="col-12 col-lg-6 text-center d-flex justify-content-center align-items-center">
									<img
										src={data.block3.childImageSharp.fluid.src}
										className="img-fluid mb-2"
										alt="Снимка от създаването на NetSurf"
									/>
								</div>
							</div>
						</div>
					</ScrollAnimation>

					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<div className="container my-3">
							<div className="row mt-5 d-flex">
								<div className="col-12 col-lg-6 order-1 d-flex justify-content-center align-items-center px-lg-4">
									<div>
										<p className="mt-4 mt-lg-0">
											Ако се налага съдействие и посещение на място,
											процедираме по следния начин:
										</p>
										<ul className="blue-ticks">
											<li>
												предупреждаваме клиента да проветри
												помещението 15 минути преди технически
												екип да посети адреса
											</li>
											<li>
												изискваме от клиента да попълни
												декларация, с която удостоверява, че скоро
												не е пътувал в чужбина и не е имал
												контакти с потенциално заразени хора
											</li>
											<li>
												молим за спазване на дистанция минимум 2
												метра
											</li>
										</ul>
									</div>
								</div>
								<div className="col-12 col-lg-6 text-center d-flex justify-content-center align-items-center">
									<iframe
										width="100%"
										height="100%"
										src="https://www.youtube.com/embed/JhTKzo4BsBo"
										frameBorder="0"
										allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
									></iframe>
								</div>
							</div>
						</div>
					</ScrollAnimation>

					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<div className="container my-3">
							<div className="row mt-5 d-flex">
								<div className="col-12 col-lg-6 order-1 order-lg-0 d-flex justify-content-center align-items-center px-lg-4">
									<div>
										<h3>
											Съвети и препоръки на официални институции
										</h3>
										<p>
											Пандемията COVID-19 оказва влияние върху
											здравето на нашите близки, бизнеса, на който
											разчитаме, здравето на глобалната икономика и
											начина, по който живеем в ежедневието си.
											Какво всъщност представлява новият коронавирус
											и как се разпространява? Какви са симптомите,
											които проявява човек и как да се предпази от
											инфекция? Защо е толкова важно да мием ръцете
											си често и да спазваме висока лична хигиена?
										</p>
										<p>
											Отговори на тези въпроси може да намерите от
											следните организации:
										</p>
										<ul className="blue-ticks">
											<li>
												<a
													href="https://www.unicef.org/bulgaria/%D0%B8%D1%81%D1%82%D0%BE%D1%80%D0%B8%D0%B8/%D0%BA%D0%BE%D1%80%D0%BE%D0%BD%D0%B0%D0%B2%D0%B8%D1%80%D1%83%D1%81-covid-19-%D0%BA%D0%B0%D0%BA%D0%B2%D0%BE-%D1%82%D1%80%D1%8F%D0%B1%D0%B2%D0%B0-%D0%B4%D0%B0-%D0%B7%D0%BD%D0%B0%D1%8F%D1%82-%D1%80%D0%BE%D0%B4%D0%B8%D1%82%D0%B5%D0%BB%D0%B8%D1%82%D0%B5"
													target="_blank"
												>
													Уницеф
												</a>
											</li>
											<li>
												<a
													href="https://www.mh.government.bg/bg/covid-19/"
													target="_blank"
												>
													Министерство на здравеопазването
												</a>
											</li>
											<li>
												<a
													href="https://www.mfa.bg/bg/customnews/main/24736"
													target="_blank"
												>
													Министерство на външните работи
												</a>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-12 col-lg-6 text-center d-flex justify-content-center align-items-center">
									<img
										src={data.block4.childImageSharp.fluid.src}
										className="img-fluid mb-2"
										alt="Снимка от създаването на NetSurf"
									/>
								</div>
							</div>
						</div>
					</ScrollAnimation>

					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<div className="container my-3">
							<div className="row mt-5 d-flex">
								<div className="col-12 col-lg-6 order-1 order-lg-1 d-flex justify-content-center align-items-center px-lg-4">
									<div>
										<h3>
											Коронавирусът COVID-19 на територията на
											България и по света
										</h3>
										<p>
											Статистика* за разпространението на вируса на
											територията на страната можете да намерите на{' '}
											<strong>
												<a
													href="https://esribulgaria.maps.arcgis.com/apps/opsdashboard/index.html#/6e2c968cf38540f29a4311295dce390f"
													target="_blank"
												>
													следния линк
												</a>
											</strong>
											.
										</p>
										<small>
											* ”Нет-Сърф.Нет” ООД не носи отговорност за
											достоверността на тази статистика.
										</small>
										<br />
										<small>
											** Информацията на тази страница е взета от
											посочените в нея източници.
										</small>
									</div>
								</div>
								<div className="col-12 col-lg-6 text-center d-flex justify-content-center align-items-center">
									<img
										src={data.block5.childImageSharp.fluid.src}
										className="img-fluid mb-2"
										alt="Снимка от създаването на NetSurf"
									/>
								</div>
							</div>
						</div>
					</ScrollAnimation>
				</section>

				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<PackageCta
						bgImg={data.ctaBgImage.childImageSharp.fluid.src}
						noSpacingBottom={true}
					>
						<h2 className="covid my-md-5 mb-2 text-white">
							Не се колебайте да се свържете с нас при възникнали въпроси,
							коментари или идеи, свързани с мерките за безопасност, които
							предприемаме.
						</h2>
						<div className="d-inline-block">
							<a
								href={'tel:088 299 1611'}
								className="btn btn-primary green text-left d-inline-block mt-2 mr-md-2 mb-2"
								id="b2b-pcta-call"
							>
								Обади се на 088 299 1611
							</a>
						</div>
						<div className="d-inline-block">
							<button
								className="btn btn-primary blue"
								id="b2b-pcta-formrequest"
								type="button"
								onClick={(_) => setShowForm(true)}
							>
								Изпрати запитване
							</button>
						</div>
					</PackageCta>
				</ScrollAnimation>
			</div>
			<FormPopup show={showForm} setShow={setShowForm} isBusiness={false} />
		</Layout>
	);
};

export const query = graphql`
	query CovidPage {
		ctaBgImage: file(relativePath: { eq: "home/cta-bg.png" }) {
			childImageSharp {
				fluid(maxHeight: 1500) {
					src
				}
			}
		}
		heroImage: file(relativePath: { eq: "covid/header.png" }) {
			childImageSharp {
				fluid(maxHeight: 1852) {
					src
				}
			}
		}
		block1: file(relativePath: { eq: "covid/block-1.png" }) {
			childImageSharp {
				fluid(maxWidth: 400) {
					...GatsbyImageSharpFluid
				}
			}
		}
		block2: file(relativePath: { eq: "covid/block-2.png" }) {
			childImageSharp {
				fluid(maxWidth: 400) {
					...GatsbyImageSharpFluid
				}
			}
		}
		block3: file(relativePath: { eq: "covid/block-3.png" }) {
			childImageSharp {
				fluid(maxWidth: 400) {
					...GatsbyImageSharpFluid
				}
			}
		}
		block4: file(relativePath: { eq: "covid/block-4.png" }) {
			childImageSharp {
				fluid(maxWidth: 400) {
					...GatsbyImageSharpFluid
				}
			}
		}
		block5: file(relativePath: { eq: "covid/block-5.png" }) {
			childImageSharp {
				fluid(maxWidth: 400) {
					...GatsbyImageSharpFluid
				}
			}
		}
		bgImage: file(relativePath: { eq: "home/home-reviews-bg.png" }) {
			childImageSharp {
				fluid {
					src
				}
			}
		}
	}
`;
